import React from "react";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Heading1 from "../ui/Heading1";
import { COLORS } from "../ui/variables";
import Heading4 from "../ui/Heading4";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "photos/404.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 775) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Page not found (404)" />
      <div
        css={css`
          position: relative;
        `}
      >
        <Img
          css={css`
            max-height: 775px;
            height: 100%;
          `}
          fluid={data.background.childImageSharp.fluid}
        />
        <div
          css={css`
            position: absolute;
            top: 0;
            z-index: 1;
            max-width: 1440px;
            width: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 20px;
          `}
        >
          <div
            css={css`
              max-width: 600px;
            `}
          >
            <Heading1
              css={css`
                color: ${COLORS.WHITE};
              `}
            >
              Ruh-roh! <br />
              Page not found
            </Heading1>
            <Heading4
              css={css`
                color: ${COLORS.WHITE};
              `}
            >
              We may be superior sniffers, but we didn't find what you're
              looking for.
            </Heading4>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
